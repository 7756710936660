import React from "react";
import PartPage, { PartInfo } from "../../PartPage/PartPage";

const partInfo: PartInfo = {
  stats: {
    armor: { comparitor: "good" },
    hitpoints: { comparitor: "good" },
    drain: { comparitor: "bad" },
    mass: { comparitor: "bad" },
    frontHitPoints: { comparitor: "good" },
    backHitPoints: { comparitor: "good" },
    rechargeRate: { comparitor: "good", precision: 2 },
  },
  partDescription: `Crafted shields used to be unbeatable by re'd work (except for the lvl 8 reward - we'll get to that), but protectonets have a higher hp potential. Even so, it is the rare protecto net that has more hp's than a well crafted mk iv shield.\nThe basis of most pvp shields is either the nym reward (protectonet) lvl 7 shield or the pilot reward "taim and bak military grade shield" lvl 8`,
  levels: {
    "1": {
      bonus: 0.02,
      stats: {
        armor: {
          best: 102.6,
        },
        hitpoints: {
          best: 102.6,
        },
        drain: {
          best: 1779,
        },
        mass: {
          best: 775,
        },
        frontHitPoints: {
          best: 270,
        },
        backHitPoints: {
          best: 286,
        },
        rechargeRate: {
          best: 2.3,
        },
      },
    },
    "2": {
      bonus: 0.03,
      stats: {
        armor: {
          best: 99.2,
        },
        hitpoints: {
          best: 99.2,
        },
        drain: {
          best: 1596,
        },
        mass: {
          best: 1567,
        },
        frontHitPoints: {
          best: 351,
        },
        backHitPoints: {
          best: 364,
        },
        rechargeRate: {
          best: 2.5,
        },
      },
    },
    "3": {
      bonus: 0.03,
      stats: {
        armor: {
          best: 181.1,
        },
        hitpoints: {
          best: 181.1,
        },
        drain: {
          best: 1635,
        },
        mass: {
          best: 2254,
        },
        frontHitPoints: {
          best: 673,
        },
        backHitPoints: {
          best: 675,
        },
        rechargeRate: {
          best: 4.4,
        },
      },
    },
    "4": {
      bonus: 0.04,
      stats: {
        armor: {
          best: 296.3,
        },
        hitpoints: {
          best: 296.3,
        },
        drain: {
          best: 1446,
        },
        mass: {
          best: 4038,
        },
        frontHitPoints: {
          best: 898,
        },
        backHitPoints: {
          best: 898,
        },
        rechargeRate: {
          best: 6.1,
        },
      },
    },
    "5": {
      bonus: 0.04,
      stats: {
        armor: {
          best: 427.2,
        },
        hitpoints: {
          best: 427.2,
        },
        drain: {
          best: 1626,
        },
        mass: {
          best: 5433,
        },
        frontHitPoints: {
          best: 1277.7,
        },
        backHitPoints: {
          best: 1238.9,
        },
        rechargeRate: {
          best: 8.76,
        },
      },
    },
    "6": {
      bonus: 0.05,
      stats: {
        armor: {
          best: 540.1,
        },
        hitpoints: {
          best: 540.1,
        },
        drain: {
          best: 1395,
        },
        mass: {
          best: 9632,
        },
        frontHitPoints: {
          best: 1688,
        },
        backHitPoints: {
          best: 1626,
        },
        rechargeRate: {
          best: 11.57,
        },
      },
    },
    "7": {
      bonus: 0.05,
      stats: {
        armor: {
          best: 613.3,
        },
        hitpoints: {
          best: 613.3,
        },
        drain: {
          best: 1516,
        },
        mass: {
          best: 14451,
        },
        frontHitPoints: {
          best: 2160,
        },
        backHitPoints: {
          best: 2237,
        },
        rechargeRate: {
          best: 16.14,
        },
      },
    },
    "8": {
      bonus: 0.06,
      stats: {
        armor: {
          best: 782.2,
        },
        hitpoints: {
          best: 782.2,
        },
        drain: {
          best: 1452,
        },
        mass: {
          best: 23248,
        },
        frontHitPoints: {
          best: 2501,
        },
        backHitPoints: {
          best: 2433.5,
        },
        rechargeRate: {
          best: 17.92,
        },
      },
    },
    "9": {
      bonus: 0.06,
      stats: {
        armor: {
          best: 748.3,
        },
        hitpoints: {
          best: 748.3,
        },
        drain: {
          best: 1992,
        },
        mass: {
          best: 43193,
        },
        frontHitPoints: {
          best: 2216,
        },
        backHitPoints: {
          best: 2396,
        },
        rechargeRate: {
          best: 14.5,
        },
      },
    },
    "10": {
      bonus: 0.07,
      stats: {
        armor: {
          best: 892.1,
        },
        hitpoints: {
          best: 892.1,
        },
        drain: {
          best: 1608,
        },
        mass: {
          best: 43073,
        },
        frontHitPoints: {
          best: 2927.7,
        },
        backHitPoints: {
          best: 2823.5,
        },
        rechargeRate: {
          best: 19.91,
        },
      },
    },
  },
};

const Shield: React.FC = () => {
  return <PartPage partInfo={partInfo} partName="Shield" />;
};

export default Shield;
