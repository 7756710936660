import React from "react";
import PartPage, { PartInfo } from "../../PartPage/PartPage";

const partInfo: PartInfo = {
  stats: {
    armor: { comparitor: "good" },
    hitpoints: { comparitor: "good" },
    drain: { comparitor: "bad" },
    mass: { comparitor: "bad" },
    energy: { comparitor: "good" },
    rechargeRate: { comparitor: "good" },
    consumption: { comparitor: "bad" },
    acceleration: { comparitor: "good" },
    topSpeed: { comparitor: "good" },
  },
  partDescription:
    "Unlike other pieces of space loot, finding a decent booster isn't hard. If you haven't looted a level 2 or level 4 booster by the time you've outgrown your prototype booster, then go to the closest bazaar and Ship Component: Booster, sort by price by lowest to highest. You'll find an abundance of level 2 boosters that will be sufficient for just about any situation except hard core pvp. The interesting thing about level 2 and level 4 boosters is that they are all uniformly decent with speeds in the mid 20's, burn times of around 10, recharges above 40. If you fly an interceptor where every last bit of mass is precious, you can get a 1k mass mark 1 crafted booster with a burn time around 10, speed in the lower 30's, and 20ish recharge. Burn time is simply booster energy/consumption, ie energy of 1000, consumption of 100 = burn time of 10.",
  levels: {
    "1": {
      bonus: 0.02,
      stats: {
        armor: {
          best: 79.2,
        },
        hitpoints: {
          best: 79.2,
        },
        drain: {
          best: 429,
        },
        mass: {
          best: 668,
        },
        energy: {
          best: 1000,
        },
        rechargeRate: {
          best: 28,
        },
        consumption: {
          best: 88,
        },
        acceleration: {
          best: 21,
        },
        topSpeed: {
          best: 15.5,
        },
      },
    },
    "2": {
      bonus: 0.03,
      stats: {
        armor: {
          best: 119.1,
        },
        hitpoints: {
          best: 119.1,
        },
        drain: {
          best: 412,
        },
        mass: {
          best: 1131,
        },
        energy: {
          best: 1500,
        },
        rechargeRate: {
          best: 41.5,
        },
        consumption: {
          best: 132,
        },
        acceleration: {
          best: 31.3,
        },
        topSpeed: {
          best: 23.4,
        },
      },
    },
    "3": {
      bonus: 0.03,
      stats: {
        armor: {
          best: 182.2,
        },
        hitpoints: {
          best: 182.2,
        },
        drain: {
          best: 441,
        },
        mass: {
          best: 1698,
        },
        energy: {
          best: 1100,
        },
        rechargeRate: {
          best: 30,
        },
        consumption: {
          best: 96.4,
        },
        acceleration: {
          best: 22.9,
        },
        topSpeed: {
          best: 17.2,
        },
      },
    },
    "4": {
      bonus: 0.04,
      stats: {
        armor: {
          best: 275.7,
        },
        hitpoints: {
          best: 275.7,
        },
        drain: {
          best: 420,
        },
        mass: {
          best: 2943,
        },
        energy: {
          best: 1650,
        },
        rechargeRate: {
          best: 46.5,
        },
        consumption: {
          best: 138.6,
        },
        acceleration: {
          best: 34.8,
        },
        topSpeed: {
          best: 26,
        },
      },
    },
    "5": {
      bonus: 0.04,
      stats: {
        armor: {
          best: 332.6,
        },
        hitpoints: {
          best: 332.6,
        },
        drain: {
          best: 466,
        },
        mass: {
          best: 4588,
        },
        energy: {
          best: 1210,
        },
        rechargeRate: {
          best: 33.7,
        },
        consumption: {
          best: 108.3,
        },
        acceleration: {
          best: 25.3,
        },
        topSpeed: {
          best: 19.1,
        },
      },
    },
    "6": {
      bonus: 0.05,
      stats: {
        armor: {
          best: 502.7,
        },
        hitpoints: {
          best: 502.7,
        },
        drain: {
          best: 430,
        },
        mass: {
          best: 7390,
        },
        energy: {
          best: 1815,
        },
        rechargeRate: {
          best: 51.5,
        },
        consumption: {
          best: 158.6,
        },
        acceleration: {
          best: 38,
        },
        topSpeed: {
          best: 28.7,
        },
      },
    },
    "7": {
      bonus: 0.05,
      stats: {
        armor: {
          best: 496.2,
        },
        hitpoints: {
          best: 496.2,
        },
        drain: {
          best: 466,
        },
        mass: {
          best: 12101,
        },
        energy: {
          best: 1331,
        },
        rechargeRate: {
          best: 36.7,
        },
        consumption: {
          best: 118.8,
        },
        acceleration: {
          best: 27.9,
        },
        topSpeed: {
          best: 21.1,
        },
      },
    },
    "8": {
      bonus: 0.06,
      stats: {
        armor: {
          best: 741.7,
        },
        hitpoints: {
          best: 741.7,
        },
        drain: {
          best: 450,
        },
        mass: {
          best: 19682,
        },
        energy: {
          best: 1996.6,
        },
        rechargeRate: {
          best: 56.7,
        },
        consumption: {
          best: 172.8,
        },
        acceleration: {
          best: 41.9,
        },
        topSpeed: {
          best: 32,
        },
      },
    },
    "9": {
      bonus: 0.06,
      stats: {
        armor: {
          best: 586.6,
        },
        hitpoints: {
          best: 586.6,
        },
        drain: {
          best: 504,
        },
        mass: {
          best: 29020,
        },
        energy: {
          best: 1464,
        },
        rechargeRate: {
          best: 40.5,
        },
        consumption: {
          best: 130.4,
        },
        acceleration: {
          best: 30.5,
        },
        topSpeed: {
          best: 23.6,
        },
      },
    },
    "10": {
      bonus: 0.07,
      stats: {
        armor: {
          best: 872.3,
        },
        hitpoints: {
          best: 872.3,
        },
        drain: {
          best: 488,
        },
        mass: {
          best: 37124,
        },
        energy: {
          best: 2196.2,
        },
        rechargeRate: {
          best: 60.8,
        },
        consumption: {
          best: 192.6,
        },
        acceleration: {
          best: 46.1,
        },
        topSpeed: {
          best: 35.2,
        },
      },
    },
  },
};

const Booster: React.FC = () => {
  return <PartPage partInfo={partInfo} partName="Booster" />;
};

export default Booster;
