import {
  createStyles,
  Divider,
  Drawer,
  IconButton,
  List,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import ListItemLink from "./ListItemLink";
import clsx from "clsx";
import React from "react";
import image from "./Beyond.png";

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    drawer: {
      overflowX: "hidden",
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    image: {
      marginTop: "auto",
      padding: theme.spacing(1),
    },
  });
});

const SideNav: React.FC<{
  open: boolean;
  handleDrawerClose: React.MouseEventHandler;
}> = ({ open, handleDrawerClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </div>
      <Divider />
      <List>
        <ListItemLink
          to={`/RE-Calc`}
          primary="Reverse Engineer"
          icon={<DirectionsCarIcon />}
        />
      </List>
      {/* <div className={classes.imageContainer}> */}
      <img className={classes.image} src={image} alt="SWG Beyond Jawa Logo" />
      {/* </div> */}
    </Drawer>
  );
};

export default SideNav;
