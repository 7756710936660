import {
  AppBar,
  createStyles,
  Fade,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Theme,
} from "@material-ui/core";
import React, { FC } from "react";
import { useRouteMatch, Switch, Route, useHistory } from "react-router-dom";
import Engine from "../Engine/Engine";
import Armor from "../Armor/Armor";
import Capacitor from "../Capacitor.tsx/Capacitor";
import Shield from "../Shield/Shield";
import Weapon from "../Weapon/Weapon";
import Droid from "../Droid/Droid";
import Booster from "../Booster/Booster";
import Reactor from "../Reactor/Reactor";
import clsx from "clsx";
import LinkCard from "../Home/LinkCard/LinkCard";
import engineImage from "./engine.png";
import shieldImage from "./shield.png";
import weaponImage from "./weapon.png";
import reactorImage from "./reactor.png";
import boosterImage from "./booster.png";
import armorImage from "./armor.png";
import capacitorImage from "./capacitor.png";
import droidImage from "./droidi.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      padding: theme.spacing(7, 3),
      overflowY: "auto",
    },
    tabs: {
      flexGrow: 1,
      width: `calc(100% - ${theme.spacing(7)}px)`,
      top: theme.spacing(8),
      left: theme.spacing(7),
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    contentOpen: {
      marginLeft: drawerWidth - theme.spacing(9),
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: `calc(100% - ${drawerWidth}px)`,
    },
  })
);

const ReverseEngineerCalc: FC<{ open: boolean }> = ({ open }) => {
  let { path, url } = useRouteMatch();
  const history = useHistory();
  const classes = useStyles();
  const setRoute = (newRoute: string) => {
    console.log(newRoute);
    history.push(newRoute);
  };
  console.log(path);
  console.log(url);
  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(classes.tabs, {
          [classes.contentOpen]: open,
        })}
      >
        <Tabs
          variant="scrollable"
          value={
            history.location.pathname !== "/RE-Calc"
              ? history.location.pathname
              : false
          }
          onChange={(e, value) => setRoute(String(value))}
        >
          <Tab label="Engine" value={`${path}/Engine`} />
          <Tab label="Armor" value={`${path}/Armor`} />
          <Tab label="Capacitor" value={`${path}/Capacitor`} />
          <Tab label="Shield" value={`${path}/Shield`} />
          <Tab label="Weapon" value={`${path}/Weapon`} />
          <Tab label="Droid" value={`${path}/Droid`} />
          <Tab label="Reactor" value={`${path}/Reactor`} />
          <Tab label="Booster" value={`${path}/Booster`} />
        </Tabs>
      </AppBar>
      <div className={classes.content}>
        <Switch>
          <Route path={`${path}`} exact>
            <Fade in>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <LinkCard
                    title="Engine"
                    route={`${path}/Engine`}
                    imageFit
                    image={engineImage}
                    imageAlt="Engine Component"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <LinkCard
                    route={`${path}/Armor`}
                    title="Armor"
                    imageFit
                    image={armorImage}
                    imageAlt="Armor Ship Component"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <LinkCard
                    route={`${path}/Capacitor`}
                    title="Capacitor"
                    imageFit
                    image={capacitorImage}
                    imageAlt="Capacitor Ship Component"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <LinkCard
                    route={`${path}/Shield`}
                    title="Shield"
                    imageFit
                    image={shieldImage}
                    imageAlt="Shield Component"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <LinkCard
                    route={`${path}/Weapon`}
                    title="Weapon"
                    imageFit
                    image={weaponImage}
                    imageAlt="Weapon Ship Component"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <LinkCard
                    route={`${path}/Droid`}
                    title="Droid Interface"
                    imageFit
                    image={droidImage}
                    imageAlt="Droid Interface Ship Component"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <LinkCard
                    route={`${path}/Booster`}
                    title="Booster"
                    imageFit
                    image={boosterImage}
                    imageAlt="Booster Ship Component"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <LinkCard
                    route={`${path}/Reactor`}
                    title="Reactor"
                    imageFit
                    image={reactorImage}
                    imageAlt="Reactor Ship Component"
                  />
                </Grid>
              </Grid>
            </Fade>
          </Route>
          <Route path={`${path}/Engine`}>
            <Engine />
          </Route>
          <Route path={`${path}/Armor`}>
            <Armor />
          </Route>
          <Route path={`${path}/Capacitor`}>
            <Capacitor />
          </Route>
          <Route path={`${path}/Shield`}>
            <Shield />
          </Route>
          <Route path={`${path}/Weapon`}>
            <Weapon />
          </Route>
          <Route path={`${path}/Droid`}>
            <Droid />
          </Route>
          <Route path={`${path}/Booster`}>
            <Booster />
          </Route>
          <Route path={`${path}/Reactor`}>
            <Reactor />
          </Route>
        </Switch>
      </div>
    </>
  );
};

export default ReverseEngineerCalc;
