import React from "react";
import PartPage, { PartInfo } from "../../PartPage/PartPage";

const partInfo: PartInfo = {
  stats: {
    armor: { comparitor: "good" },
    hitPoints: { comparitor: "good" },
    mass: { comparitor: "bad" },
    regeneration: { comparitor: "good" },
  },
  partDescription: `The ideal reactor would deliver just enough gen to power everything you need and at the same time be as light as possible. Reactor overloads greatly increase the gen created by the reactor and are necessary to building an effective ship. RO3 = 1.6 X Gen. RO4 = 1.9 X Gen. RO4 is only available late in the pilot grind.\nThe appearance of the modified fusion reactor (A looted, 25 use schem from Mustafar) has made Mark IV and Mark V reactors obsolete. Although the Mark V reactor can reach about 1k higher gen, this comes at the expense of being around 30k mass heavier (not worth it).\nReactors can be mass/gen customized when mass is an issue. For example, if you know you need exactly 14.2k gen, you can ask your shipwright to build the lightest possible reactor that delivers that gen (this is going to wind up being a Mark III with a Mark I overcharger).\nThe Lightest possible reactor noted above under the circumstances described is incorrect. the lightest possible reactor will be a Mark IV reactor with Reactor Limiter IV, with Mass modified as low as possible on the limiter. This will generally yield a Reactor, that once experimented on properly, can get into the 4-5.9k mass range, while yielding a generation rate of around the 15k's. Endlarl Wayfar, Ahazi Server.`,
  levels: {
    "1": {
      bonus: 0.02,
      stats: {
        hitPoints: {
          best: 85.3,
        },
        armor: {
          best: 85.3,
        },
        mass: {
          best: 811.9,
        },
        regeneration: {
          best: 13583.4,
        },
      },
    },
    "2": {
      bonus: 0.03,
      stats: {
        hitPoints: {
          best: 126.5,
        },
        armor: {
          best: 126.5,
        },
        mass: {
          best: 1199.6,
        },
        regeneration: {
          best: 13811.9,
        },
      },
    },
    "3": {
      bonus: 0.03,
      stats: {
        hitPoints: {
          best: 202.7,
        },
        armor: {
          best: 202.7,
        },
        mass: {
          best: 1433.1,
        },
        regeneration: {
          best: 15550.6,
        },
      },
    },
    "4": {
      bonus: 0.04,
      stats: {
        hitPoints: {
          best: 299.3,
        },
        armor: {
          best: 299.3,
        },
        mass: {
          best: 2495,
        },
        regeneration: {
          best: 18275.1,
        },
      },
    },
    "5": {
      bonus: 0.04,
      stats: {
        hitPoints: {
          best: 362.3,
        },
        armor: {
          best: 362.3,
        },
        mass: {
          best: 3801.1,
        },
        regeneration: {
          best: 20682,
        },
      },
    },
    "6": {
      bonus: 0.05,
      stats: {
        hitPoints: {
          best: 551.5,
        },
        armor: {
          best: 551.5,
        },
        mass: {
          best: 6914.8,
        },
        regeneration: {
          best: 27029.8,
        },
      },
    },
    "7": {
      bonus: 0.05,
      stats: {
        armor: {
          best: 534.2,
        },
        hitPoints: {
          best: 534.2,
        },
        mass: {
          best: 9766.4,
        },
        regeneration: {
          best: 33414.6,
        },
      },
    },
    "8": {
      bonus: 0.06,
      stats: {
        armor: {
          best: 810.9,
        },
        hitPoints: {
          best: 810.9,
        },
        mass: {
          best: 17849.3,
        },
        regeneration: {
          best: 33763.3,
        },
      },
    },
    "9": {
      bonus: 0.06,
      stats: {
        armor: {
          best: 787.2,
        },
        hitPoints: {
          best: 787.2,
        },
        mass: {
          best: 22692,
        },
        regeneration: {
          best: 35777,
        },
      },
    },
    "10": {
      bonus: 0.07,
      stats: {
        armor: {
          best: 1197.7,
        },
        hitPoints: {
          best: 1197.7,
        },
        mass: {
          best: 33377.1,
        },
        regeneration: {
          best: 45286.6,
        },
      },
    },
  },
};

const Reactor: React.FC = () => {
  return <PartPage partInfo={partInfo} partName="Reactor" />;
};

export default Reactor;
