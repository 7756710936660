import React from "react";
import PartPage from "../../PartPage/PartPage";
import { PartInfo } from "../../PartPage/PartPage";

const partInfo: PartInfo = {
  stats: {
    armor: {
      comparitor: "good",
    },
    hitpoints: {
      comparitor: "good",
    },
    mass: {
      comparitor: "bad",
    },
  },
  partDescription: `You'll want to replace that prototype armor as soon as you reach a point where enemy ships are melting it with some frequency (tier 2-3). A sweet spot for crafted armor is Mark 2 and Mark 3 plates. These plates can give 500 (Mk2) -1200 (Mk3) protection and range from 2k(Mk2) -6k(Mk3) mass. Even if you are flying heavy ships, the jump from Mark 3 to Mark 4 armor mass is huge, with mark 4 plates coming in around 14k and 1900HP's. Remember that there is no rule that you have to use the same size plate on front or back. RE'd armor can be fantastic, and is one of the few areas where odd parts are also viable. However, in terms of looting, it will take a while to get pieces that can trump crafted. Look for lvl 8 plates with masses under 12k to re with a fibertech (nym's starmap reward).\nWhen you are putting together your ship to take to master and need every last bit of mass, keep in mind that SW's can make mass customized armor easily. There is great flexibility in the mass available because of the use of mass reduction kits, reinforcement panels, as well as the ability to experiment directly on mass. In other words, if you have 4.9k mass left but can only find 2.5k pieces and 5.2k pieces on vendors, your local shipwright can easily make a piece with 4.85 or whatever mass you want. This gives you the most protection for the mass you have left.`,
  levels: {
    "1": {
      bonus: 0.02,
      customMessage: "Reverse Engineering this tier is not recommended.",
      stats: {
        armor: {
          best: 239,
        },
        hitpoints: {
          best: 239,
        },
        mass: {
          best: 575,
        },
      },
    },
    "2": {
      bonus: 0.03,
      customMessage: "Reverse Engineering this tier is not recommended.",
      stats: {
        armor: {
          best: 322,
        },
        hitpoints: {
          best: 322,
        },
        mass: {
          best: 902,
        },
      },
    },
    "3": {
      bonus: 0.03,
      customMessage: "Reverse Engineering this tier is not recommended.",
      stats: {
        armor: {
          best: 594,
        },
        hitpoints: {
          best: 594,
        },
        mass: {
          best: 1126,
        },
      },
    },
    "4": {
      bonus: 0.04,
      customMessage: "Reverse Engineering this tier is not recommended.",
      stats: {
        armor: {
          best: 740,
        },
        hitpoints: {
          best: 740,
        },
        mass: {
          best: 1839,
        },
      },
    },
    "5": {
      bonus: 0.04,
      customMessage: "Reverse Engineering this tier is not recommended.",
      stats: {
        armor: {
          best: 1110,
        },
        hitpoints: {
          best: 1110,
        },
        mass: {
          best: 2046,
        },
      },
    },
    "6": {
      bonus: 0.05,
      customMessage: "Reverse Engineering this tier is not recommended.",
      stats: {
        armor: {
          best: 1431,
        },
        hitpoints: {
          best: 1431,
        },
        mass: {
          best: 3320,
        },
      },
    },
    "7": {
      bonus: 0.05,
      customMessage: "Reverse Engineering this tier is not recommended.",
      stats: {
        armor: {
          best: 1617,
        },
        hitpoints: {
          best: 1617,
        },
        mass: {
          best: 3910,
        },
      },
    },
    "8": {
      bonus: 0.06,
      customMessage:
        "Reverse Engineering this tier is not recommended. Look out for Nym's Starmap reward armor.",
      stats: {
        armor: {
          best: 2102,
        },
        hitpoints: {
          best: 2102,
        },
        mass: {
          best: 5694,
        },
      },
    },
    "9": {
      bonus: 0.06,
      customMessage: "Reverse Engineering this tier is not recommended.",
      stats: {
        armor: {
          best: 2135,
        },
        hitpoints: {
          best: 2135,
        },
        mass: {
          best: 4244,
        },
      },
    },
    "10": {
      bonus: 0.07,
      customMessage: "Reverse Engineering this tier is not recommended.",
      stats: {
        armor: {
          best: 2492,
        },
        hitpoints: {
          best: 2492,
        },
        mass: {
          best: 9285,
        },
      },
    },
  },
};

const Armor: React.FC = () => {
  return <PartPage partInfo={partInfo} partName="Armor" />;
};

export default Armor;
