import { createStyles, Fade, Grid, makeStyles, Theme } from "@material-ui/core";
import React, { FC } from "react";
import reImage from "./Firespray.jpg";
import LinkCard from "./LinkCard/LinkCard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      overflowY: "auto",
    },
  })
);

const Home: FC<{}> = () => {
  const classes = useStyles();
  return (
    <Fade in>
      <div className={classes.content}>
        <Grid container>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <LinkCard
              title="Reverse Engineer Calculator"
              route="/RE-Calc"
              image={reImage}
              imageAlt="KSE Firespray"
            />
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

export default Home;
