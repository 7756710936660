import React from "react";
import PartPage, { PartInfo } from "../../PartPage/PartPage";

const partInfo: PartInfo = {
  stats: {
    armor: { comparitor: "good" },
    hitpoints: { comparitor: "good" },
    drain: { comparitor: "bad" },
    mass: { comparitor: "bad" },
    energy: { comparitor: "good" },
    rechargeRate: { comparitor: "good" },
  },
  partDescription: `Buying a good cap is one area where you shouldn't skimp, as a bad cap will make your life much more difficult. Crafted capacitors are usually made with either the high recharge sub or the heavy battery (high energy sub). While many pilots tend to focus on recharge, in point of fact capacitors made with the high energy sub have a greater drain time.\nIf your sever has active space guilds and shipwrights who themselves are active in space, then in your search for caps you should find well RE'd Level 2, 4, and 6 pieces for reasonable prices. If your server is soft on the space side, then you'll need to look for crafted caps or work on a RE (re-engineering) project of your own. Capacitors are one area in which re'd goods really trump crafted. The best mass/performance lvls are 2 (single gun interceptors) and 6 (2-3 gun ships). There is a role for lvl 8 and 10 capacitors in 4 gun ships.`,
  levels: {
    "1": {
      bonus: 0.02,
      stats: {
        armor: {
          best: 84,
        },
        hitpoints: {
          best: 84,
        },
        drain: {
          best: 714,
        },
        mass: {
          best: 730,
        },
        energy: {
          best: 729,
        },
        rechargeRate: {
          best: 25.9,
        },
      },
    },
    "2": {
      bonus: 0.03,
      stats: {
        armor: {
          best: 112.2,
        },
        hitpoints: {
          best: 112.2,
        },
        drain: {
          best: 554,
        },
        mass: {
          best: 1078,
        },
        energy: {
          best: 1185,
        },
        rechargeRate: {
          best: 41.3,
        },
      },
    },
    "3": {
      bonus: 0.03,
      stats: {
        armor: {
          best: 195.1,
        },
        hitpoints: {
          best: 195.1,
        },
        drain: {
          best: 757,
        },
        mass: {
          best: 2082,
        },
        energy: {
          best: 736,
        },
        rechargeRate: {
          best: 28,
        },
      },
    },
    "4": {
      bonus: 0.04,
      stats: {
        armor: {
          best: 293.9,
        },
        hitpoints: {
          best: 293.9,
        },
        drain: {
          best: 541,
        },
        mass: {
          best: 2761,
        },
        energy: {
          best: 1204,
        },
        rechargeRate: {
          best: 45,
        },
      },
    },
    "5": {
      bonus: 0.04,
      stats: {
        armor: {
          best: 360.8,
        },
        hitpoints: {
          best: 360.8,
        },
        drain: {
          best: 689,
        },
        mass: {
          best: 4474,
        },
        energy: {
          best: 796,
        },
        rechargeRate: {
          best: 30.2,
        },
      },
    },
    "6": {
      bonus: 0.05,
      stats: {
        armor: {
          best: 528.6,
        },
        hitpoints: {
          best: 528.6,
        },
        drain: {
          best: 593,
        },
        mass: {
          best: 7300,
        },
        energy: {
          best: 1452,
        },
        rechargeRate: {
          best: 50.4,
        },
      },
    },
    "7": {
      bonus: 0.05,
      stats: {
        armor: {
          best: 504.9,
        },
        hitpoints: {
          best: 504.9,
        },
        drain: {
          best: 768,
        },
        mass: {
          best: 11596,
        },
        energy: {
          best: 955,
        },
        rechargeRate: {
          best: 34.2,
        },
      },
    },
    "8": {
      bonus: 0.06,
      stats: {
        armor: {
          best: 802,
        },
        hitpoints: {
          best: 802,
        },
        drain: {
          best: 706,
        },
        mass: {
          best: 23037,
        },
        energy: {
          best: 1437,
        },
        rechargeRate: {
          best: 55.7,
        },
      },
    },
    "9": {
      bonus: 0.06,
      stats: {
        armor: {
          best: 629.4,
        },
        hitpoints: {
          best: 629.4,
        },
        drain: {
          best: 817,
        },
        mass: {
          best: 32631,
        },
        energy: {
          best: 954,
        },
        rechargeRate: {
          best: 37.9,
        },
      },
    },
    "10": {
      bonus: 0.07,
      stats: {
        armor: {
          best: 947.4,
        },
        hitpoints: {
          best: 947.4,
        },
        drain: {
          best: 707,
        },
        mass: {
          best: 36646,
        },
        energy: {
          best: 1701,
        },
        rechargeRate: {
          best: 60,
        },
      },
    },
  },
};

const Capacitor: React.FC = () => {
  return <PartPage partInfo={partInfo} partName="Capacitor" />;
};

export default Capacitor;
