import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  createStyles,
  CssBaseline,
  makeStyles,
  Theme,
  ThemeProvider,
} from "@material-ui/core";
import React from "react";
import TopBar from "./AppBar/AppBar";
import SideNav from "./SideNav/SideNav";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
import ReverseEngineerCalc from "./Pages/ReverseEngineerCalc/ReverseEngineerCalc";
import Home from "./Pages/Home/Home";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    content: {
      flexGrow: 1,
      // padding: theme.spacing(3),
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
  })
);

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#2c4d86",
    },
    secondary: {
      main: "#a6bfe0",
    },
  },
});

function App() {
  const [open, setOpen] = React.useState(false);

  const handleDrawerClose: React.MouseEventHandler = (e: React.MouseEvent) => {
    setOpen(false);
  };

  const handleDrawerOpen: React.MouseEventHandler = (e: React.MouseEvent) => {
    setOpen(true);
  };

  const classes = useStyles();
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          <Router>
            <TopBar open={open} handleDrawerOpen={handleDrawerOpen} />
            <SideNav open={open} handleDrawerClose={handleDrawerClose} />
            <div className={classes.content}>
              <div className={classes.toolbar} />
              <Route path="/" exact>
                <Home />
              </Route>
              <Route path="/RE-Calc">
                <ReverseEngineerCalc open={open} />
              </Route>
            </div>
          </Router>
        </div>
      </ThemeProvider>
    </div>
  );
}

export default App;
