import MenuIcon from "@material-ui/icons/Menu";
import React from "react";
import {
  AppBar,
  Breadcrumbs,
  IconButton,
  LinkProps,
  Toolbar,
  Typography,
  Link,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Link as RouterLink, useLocation } from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    homeButton: {
      color: theme.palette.text.primary,
      textDecoration: "none",
    },
  })
);

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

export const UnstyledRouterLink = (props: LinkRouterProps) => (
  <RouterLink style={{ textDecoration: "none", display: "flex" }} {...props} />
);

export const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={UnstyledRouterLink as any} />
);

const TopBar: React.FC<{
  open: boolean;
  handleDrawerOpen: React.MouseEventHandler;
}> = ({ open, handleDrawerOpen }) => {
  const classes = useStyles();
  const location = useLocation();

  const generateBreadCrumbs = () => {
    const pathnames = location.pathname.split("/").filter((x) => x);
    return (
      <Breadcrumbs aria-label="breadcrumb">
        <LinkRouter color="inherit" to="/">
          Home
        </LinkRouter>
        {pathnames.map((value, index) => {
          const last = index === pathnames.length - 1;
          const to = `/${pathnames.slice(0, index + 1).join("/")}`;
          const lastIndex = to.lastIndexOf("/");
          const pageName = to.substring(lastIndex + 1);
          return last ? (
            <Typography color="textPrimary" key={to}>
              {pageName}
            </Typography>
          ) : (
            <LinkRouter color="inherit" to={to} key={to}>
              {pageName}
            </LinkRouter>
          );
        })}
      </Breadcrumbs>
    );
  };

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: open,
          })}
        >
          <MenuIcon />
        </IconButton>
        {generateBreadCrumbs()}
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
