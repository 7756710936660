import {
  Card,
  CardActionArea,
  CardMedia,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { FC } from "react";
import { LinkRouter } from "../../../AppBar/AppBar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    anchor: {
      textDecoration: "none",
    },
    card: {
      flexGrow: 1,
      overflowY: "auto",
    },
    titleBox: {
      padding: theme.spacing(1, 2),
    },
    fit: {
      objectFit: "contain",
    },
  })
);

const LinkCard: FC<{
  route: string;
  title: string;
  image?: string;
  imageAlt?: string;
  imageFit?: boolean;
}> = ({ route, title, image, imageAlt, imageFit = false }) => {
  const classes = useStyles();
  return (
    <LinkRouter to={route} className={classes.anchor}>
      <Card className={classes.card}>
        <CardActionArea>
          <div className={classes.titleBox}>
            <Typography variant={"h6"}>{title}</Typography>
          </div>
          {image && (
            <CardMedia
              className={imageFit ? classes.fit : ""}
              component="img"
              height="160"
              image={image}
              alt={imageAlt}
            />
          )}
        </CardActionArea>
      </Card>
    </LinkRouter>
  );
};

export default LinkCard;
