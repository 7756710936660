import React from "react";
import PartPage, { PartInfo } from "../../PartPage/PartPage";

const partInfo: PartInfo = {
  stats: {
    armor: { comparitor: "good" },
    hitpoints: { comparitor: "good" },
    drain: { comparitor: "bad" },
    mass: { comparitor: "bad" },
    pitch: { comparitor: "good" },
    yaw: { comparitor: "good" },
    roll: { comparitor: "good" },
    topSpeed: { comparitor: "good" },
  },
  partDescription: `The 3 most common engines seen in pvp ships are the RE'd 8 (see QID above), lvl 10 engines, and a mk V high speed crafted. Less commonly seen are "pre-nerf" lvl 6 engines, non pre nerf lvl 6's, and then pyr mk V engines (sorta a poor man's QID). Lvl 10 Engines are reserved for ships with a generous mass, for example adv. x's, b-wings, kse's. Lvl 6 engines are used in light interceptors. The one advantage crafted engines have over re'd is a high top speed. However, this is somewhat mitigated by the fact that the reactor drain makes it extremely hard to run them on EO4.`,
  levels: {
    "1": {
      bonus: 0.02,
      customMessage: "Reverse engineering not recommended for this tier.",
      stats: {
        armor: {
          best: 83.5,
        },
        hitpoints: {
          best: 83.5,
        },
        drain: {
          best: 1256,
        },
        mass: {
          best: 707,
        },
        pitch: {
          best: 49.7,
        },
        yaw: {
          best: 49.4,
        },
        roll: {
          best: 50.2,
        },
        topSpeed: {
          best: 47.5,
        },
      },
    },
    "2": {
      bonus: 0.03,
      stats: {
        armor: {
          best: 104.9,
        },
        hitpoints: {
          best: 104.9,
        },
        drain: {
          best: 1219.2,
        },
        mass: {
          best: 1340.9,
        },
        pitch: {
          best: 61.7,
        },
        yaw: {
          best: 62.6,
        },
        roll: {
          best: 61.8,
        },
        topSpeed: {
          best: 60.3,
        },
      },
    },
    "3": {
      bonus: 0.03,
      stats: {
        armor: {
          best: 184.3,
        },
        hitpoints: {
          best: 184.3,
        },
        drain: {
          best: 1173,
        },
        mass: {
          best: 1829,
        },
        pitch: {
          best: 52.4,
        },
        yaw: {
          best: 53.1,
        },
        roll: {
          best: 52.7,
        },
        topSpeed: {
          best: 55.5,
        },
      },
    },
    "4": {
      bonus: 0.04,
      stats: {
        armor: {
          best: 241.7,
        },
        hitpoints: {
          best: 241.7,
        },
        drain: {
          best: 1285,
        },
        mass: {
          best: 3411,
        },
        pitch: {
          best: 66.1,
        },
        yaw: {
          best: 64.6,
        },
        roll: {
          best: 64.5,
        },
        topSpeed: {
          best: 68.8,
        },
      },
    },
    "5": {
      bonus: 0.04,
      stats: {
        armor: {
          best: 342.4,
        },
        hitpoints: {
          best: 342.4,
        },
        drain: {
          best: 1201.9,
        },
        mass: {
          best: 4982,
        },
        pitch: {
          best: 56.3,
        },
        yaw: {
          best: 56.2,
        },
        roll: {
          best: 57.5,
        },
        topSpeed: {
          best: 62.5,
        },
      },
    },
    "6": {
      bonus: 0.05,
      stats: {
        armor: {
          best: 453.2,
        },
        hitpoints: {
          best: 453.2,
        },
        drain: {
          best: 1305,
        },
        mass: {
          best: 8329,
        },
        pitch: {
          best: 69.8,
        },
        yaw: {
          best: 70.4,
        },
        roll: {
          best: 70.4,
        },
        topSpeed: {
          best: 76,
        },
      },
    },
    "7": {
      bonus: 0.05,
      stats: {
        armor: {
          best: 510,
        },
        hitpoints: {
          best: 510,
        },
        drain: {
          best: 1293.4,
        },
        mass: {
          best: 13320,
        },
        pitch: {
          best: 60.9,
        },
        yaw: {
          best: 60.8,
        },
        roll: {
          best: 59.5,
        },
        topSpeed: {
          best: 71.8,
        },
      },
    },
    "8": {
      bonus: 0.06,
      stats: {
        armor: {
          best: 662.8,
        },
        hitpoints: {
          best: 662.8,
        },
        drain: {
          best: 1357,
        },
        mass: {
          best: 21925,
        },
        pitch: {
          best: 74.5,
        },
        yaw: {
          best: 74.5,
        },
        roll: {
          best: 74.5,
        },
        topSpeed: {
          best: 106.1,
        },
      },
    },
    "9": {
      bonus: 0.06,
      stats: {
        armor: {
          best: 597.9,
        },
        hitpoints: {
          best: 597.9,
        },
        drain: {
          best: 1333.6,
        },
        mass: {
          best: 32994,
        },
        pitch: {
          best: 62.7,
        },
        yaw: {
          best: 62.2,
        },
        roll: {
          best: 63,
        },
        topSpeed: {
          best: 80,
        },
      },
    },
    "10": {
      bonus: 0.07,
      stats: {
        armor: {
          best: 782.6,
        },
        hitpoints: {
          best: 782.6,
        },
        drain: {
          best: 1286,
        },
        mass: {
          best: 38996,
        },
        pitch: {
          best: 80.9,
        },
        yaw: {
          best: 81.2,
        },
        roll: {
          best: 80.4,
        },
        topSpeed: {
          best: 97.3,
        },
      },
    },
  },
};

const Engine: React.FC = () => {
  return <PartPage partInfo={partInfo} partName="Engine" />;
};

export default Engine;
