import {
  createStyles,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  Theme,
  Typography,
  Input,
  InputAdornment,
  Paper,
  Divider,
  Button,
  Fade,
} from "@material-ui/core";
import { deepPurple } from "@material-ui/core/colors";
import clsx from "clsx";
import React, { useState } from "react";

export interface PartInfo {
  stats: {
    [key: string]: {
      comparitor: "good" | "bad";
      precision?: number;
    };
  };
  partDescription: string;
  levels: {
    [key: string]: {
      bonus: number;
      customMessage?: string;
      stats: {
        [key: string]: {
          best: number;
        };
      };
    };
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: "flex",
      margin: theme.spacing(0, 2),
      flexDirection: "column",
      paddingTop: "28px",
    },
    forms: {
      display: "flex",
      flexDirection: "column",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    input: {
      margin: theme.spacing(1),
      maxWidth: theme.spacing(15),
      minWidth: theme.spacing(15),
      padding: theme.spacing(1),
      borderRadius: theme.spacing(1),
      "& label": {
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1),
      },
    },
    formContainer: {
      display: "flex",
    },
    formAndTitle: {
      display: "flex",
      flexDirection: "column",
    },
    green: {
      backgroundColor: theme.palette.success.main,
    },
    red: {
      backgroundColor: theme.palette.error.main,
    },
    yellow: {
      backgroundColor: theme.palette.info.main,
    },
    plain: {
      backgroundColor: theme.palette.grey[500],
    },
    purple: {
      backgroundColor: deepPurple[500],
    },
    descriptionBox: {
      padding: theme.spacing(2),
    },
    divider: {
      margin: theme.spacing(2, 0),
    },
    legend: { display: "flex" },
    selectAndLegend: {
      display: "flex",
      justifyContent: "space-between",
    },
    legendTile: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(1),
    },
    legendBox: {
      height: theme.spacing(3),
      width: theme.spacing(3),
      borderRadius: theme.spacing(0.5),
      marginRight: theme.spacing(1),
    },
    yourBox: {
      border: "1px solid " + theme.palette.text.primary,
      borderRadius: theme.spacing(0.5),
      display: "flex",
    },
    formHeader: {
      display: "flex",
      justifyContent: "space-between",
    },
    resetButton: {
      margin: theme.spacing(1),
    },
    isBest: {
      backgroundColor: theme.palette.primary.main,
    },
  })
);

interface FormState {
  [key: string]: {
    value: string;
    error: string;
    computed: number;
    precision: number;
  };
}

function getPrecision(num: string) {
  var match = ("" + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
  if (!match) {
    return 0;
  }
  return Math.max(
    0,
    // Number of digits right of decimal point.
    (match[1] ? match[1].length : 0) -
      // Adjust for scientific notation.
      (match[2] ? +match[2] : 0)
  );
}

const PartPage: React.FC<{ partInfo: PartInfo; partName: string }> = ({
  partInfo,
  partName,
}) => {
  const [level, setLevel] = useState("1");
  const initialState = Object.keys(partInfo.stats).reduce((acc, statName) => {
    acc[statName] = { value: "0", error: "", computed: 0, precision: 0 };
    return acc;
  }, {} as FormState);
  const initialBests = Object.keys(partInfo.stats).reduce((acc, statName) => {
    acc[statName] = 0;
    return acc;
  }, {} as { [key: string]: number });
  const [formState, setFormState] = useState<FormState[]>([initialState]);
  const [bests, setBests] = useState(initialBests);
  const classes = useStyles();
  const setField = (formIndex: number, field: string, value: string) => {
    const newState = [...formState];
    const newFormState = newState[formIndex];
    newFormState[field].precision = getPrecision(value);
    newFormState[field].value = value;
    const multiplier =
      partInfo.stats[field].comparitor === "good"
        ? 1 + partInfo.levels[level].bonus
        : 1 - partInfo.levels[level].bonus;
    const isGood = partInfo.stats[field].comparitor === "good";
    const values = newState
      .map((form) => {
        return parseFloat(form[field].value);
      })
      .filter((val) => Number(val) !== 0);
    const newBest = isGood ? Math.max(...values) : Math.min(...values);
    const newBests = { ...bests };
    newBests[field] = newBest;
    setBests(newBests);
    const computedValue = parseFloat(value) * multiplier;
    newFormState[field].computed = value ? computedValue : 0;
    setFormState(newState);
  };
  const setRELevel = (levelStr: string) => {
    const previousLevel = formState.length;
    const newLevel = Number(levelStr);
    if (previousLevel === newLevel) return;
    const newFormState = [...formState];
    if (previousLevel > newLevel) {
      newFormState.length = newLevel;
    } else {
      for (let i = 0; i < newLevel - previousLevel; i++) {
        const newInitialState = JSON.parse(JSON.stringify(initialState));
        newFormState.push(newInitialState);
      }
    }
    setLevel(levelStr);
    setFormState(newFormState);
  };
  const clearFormState = () => {
    const currentLevel = Number(level);
    const newFormState = [];
    for (let i = 0; i < currentLevel; i++) {
      const newInitialState = JSON.parse(JSON.stringify(initialState));
      newFormState.push(newInitialState);
    }
    setFormState(newFormState);
  };
  return (
    <Fade in>
      <div>
        <Typography variant={"h1"}>{partName}</Typography>
        <Paper className={classes.descriptionBox}>
          {partInfo.partDescription.split("\n").map((text, idx) => (
            <Typography key={`${idx}`} gutterBottom variant="body1">
              {text}
            </Typography>
          ))}
        </Paper>
        <Divider className={classes.divider} />
        <Typography variant={"subtitle1"} gutterBottom>
          Enter your {partName.toLowerCase()} stats to compare against known
          high-end values.
        </Typography>
        <div className={classes.formHeader}>
          <div className={classes.selectAndLegend}>
            <FormControl className={classes.formControl}>
              <InputLabel id="Choose the Reverse Engineer Level">
                RE Level
              </InputLabel>
              <Select
                labelId="Choose the Reverse Engineer Level"
                id="level-select"
                value={level}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                  setRELevel(String(e.target.value))
                }
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
                  <MenuItem key={num} value={num.toString()}>
                    {num}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <Button
            className={classes.resetButton}
            variant="contained"
            color="default"
            onClick={() => clearFormState()}
          >
            Clear Data
          </Button>
        </div>
        {partInfo.levels[level].customMessage && (
          <Typography variant="body1" gutterBottom color="secondary">
            Please note: {partInfo.levels[level].customMessage}
          </Typography>
        )}
        <div className={classes.legend}>
          <div className={classes.legendTile}>
            <div className={clsx(classes.legendBox, classes.plain)} />
            <span>{"Invalid"}</span>
          </div>
          <div className={classes.legendTile}>
            <div className={clsx(classes.legendBox, classes.red)} />
            <span>{"Poor"}</span>
          </div>
          <div className={classes.legendTile}>
            <div className={clsx(classes.legendBox, classes.yellow)} />
            <span>{"Good"}</span>
          </div>
          <div className={classes.legendTile}>
            <div className={clsx(classes.legendBox, classes.green)} />
            <span>{"Excellent"}</span>
          </div>
        </div>
        <div className={classes.formContainer}>
          <div className={classes.formAndTitle}>
            <Typography variant={"subtitle2"} gutterBottom align={"center"}>
              Yours
            </Typography>
            <div className={classes.yourBox}>
              {formState.map((_, index) => {
                const formIndex = index;
                return (
                  <Fade key={index} in>
                    <form className={classes.forms}>
                      <Typography variant="subtitle1" align="center">
                        {formIndex + 1}
                      </Typography>
                      {Object.keys(formState[formIndex]).map((stat, idx) => {
                        const { value, error } = formState[formIndex][stat];
                        const isBest =
                          parseFloat(value) !== 0 &&
                          bests[stat] === parseFloat(value);
                        return (
                          <TextField
                            key={formIndex + stat}
                            required
                            error={!!error}
                            helperText={error}
                            className={clsx(classes.input, {
                              [classes.isBest]: isBest,
                            })}
                            color={isBest ? "secondary" : "primary"}
                            id={stat}
                            label={stat}
                            type="number"
                            value={String(value).replace(/^0+/, "")}
                            onChange={(e) =>
                              setField(formIndex, stat, e.target.value)
                            }
                          />
                        );
                      })}
                    </form>
                  </Fade>
                );
              })}
            </div>
          </div>
          <div className={classes.formAndTitle}>
            <Typography variant={"subtitle2"} gutterBottom align={"center"}>
              Base
            </Typography>
            <div className={classes.form}>
              {Object.keys(partInfo.stats).map((stat, idx) => {
                const value = partInfo.levels[level].stats[stat]?.best;
                const fixedPrecision = partInfo.stats[stat].precision ?? 1;
                return (
                  <TextField
                    key={`base-${stat}-idx-${value}`}
                    disabled
                    className={classes.input}
                    id={`Unicorn ${stat}`}
                    label={stat}
                    type="number"
                    value={value.toFixed(fixedPrecision)}
                  />
                );
              })}
            </div>
          </div>
          <div className={classes.formAndTitle}>
            <Typography variant={"subtitle2"} gutterBottom align={"center"}>
              Computed
            </Typography>
            <div className={classes.form}>
              {Object.keys(partInfo.stats).map((stat, idx) => {
                const isGood = partInfo.stats[stat].comparitor === "good";
                const { values, precisions } = formState.reduce(
                  (acc, form) => {
                    form[stat].computed && acc.values.push(form[stat].computed);
                    acc.precisions.push(form[stat].precision);
                    return acc;
                  },
                  { values: [], precisions: [] } as {
                    values: number[];
                    precisions: number[];
                  }
                );
                const value =
                  values.length === 0
                    ? 0
                    : isGood
                    ? Math.max(...values)
                    : Math.min(...values);
                const fixedPrecision = Math.max(...precisions);
                return (
                  <TextField
                    key={`computed-${stat}-idx-${value}`}
                    disabled
                    className={classes.input}
                    id={`Computed ${stat}`}
                    label={stat}
                    type="number"
                    value={value.toFixed(fixedPrecision)}
                  />
                );
              })}
            </div>
          </div>
          <div className={classes.formAndTitle}>
            <Typography variant={"subtitle2"} gutterBottom align={"center"}>
              Quality
            </Typography>
            <div className={classes.form}>
              {Object.keys(partInfo.stats).map((stat, idx) => {
                const isGood = partInfo.stats[stat].comparitor === "good";
                const values = formState
                  .map((form) => {
                    return parseFloat(form[stat].value);
                  })
                  .filter((val) => Number(val) !== 0);
                const value =
                  values.length === 0
                    ? 0
                    : isGood
                    ? Math.max(...values)
                    : Math.min(...values);
                const best = partInfo.levels[level].stats[stat].best;
                const isValid = value && best;
                const fixedPrecision = partInfo.stats[stat].precision ?? 1;
                const quality = !isValid
                  ? 0
                  : isGood
                  ? value / best
                  : best / value;
                const qualityAsPercent = quality * 100;
                const colorClass =
                  qualityAsPercent === 0
                    ? "plain"
                    : qualityAsPercent > 120
                    ? "plain"
                    : qualityAsPercent > 100
                    ? "green"
                    : qualityAsPercent > 85
                    ? "yellow"
                    : "red";
                return (
                  <FormControl
                    key={`quality-${stat}-idx-${qualityAsPercent}`}
                    className={clsx(classes.input, classes[colorClass])}
                  >
                    <InputLabel>{stat}</InputLabel>
                    <Input
                      endAdornment={
                        <InputAdornment position="end">%</InputAdornment>
                      }
                      readOnly
                      id={`Computed ${stat}`}
                      type="number"
                      value={qualityAsPercent.toFixed(fixedPrecision)}
                    />
                  </FormControl>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default PartPage;
